// This file contains default function configs for transform functions.

import { transforms } from '../../dazzler-constants';

const defaultConfig = {
    [transforms.databaseQuery]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'database',
        },
    },
    [transforms.enrichment]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'aws',
        },
    },
    [transforms.gotransverseQuery]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'gotransverse',
        },
    },
    [transforms.salesforceBulk]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'salesforce',
        },
    },
    [transforms.salesforceQuery]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'salesforce',
        },
    },
    [transforms.sapSbQuery]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'sap_sb',
        },
    },
    [transforms.zuoraImportUsage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'zuora',
        },
    },
    [transforms.zuoraPostUsage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'zuora',
        },
    },
    [transforms.zuoraDataQuery]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'zuora',
        },
    },
    [transforms.xmap]: {
        keepFields: {
            enabled: true,
        },
        mappings: [],
    },
    [transforms.deduplicate]: {
        // eslint-disable-next-line
        key: '${payload}',
    },
};

export default defaultConfig;
